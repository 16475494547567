import Immutable from 'immutable';
import * as Constants from 'const';
import * as Models from 'models';
import { getDesktopBrandStyles } from 'utils/brandStyles';
import { getDefaultColorFromBrandStyle } from 'utils/brandStyles/getColorTypeFromBrandStyle';
import * as editorUtils from 'utils/editor';

type BrandColorProperties = {
  colorHEX: string;
  colorName: string;
  colorTint?: number;
};

function getDefaultBrandStyle(
  brandColor: string,
  colors: Models.BrandColorsList,
): Models.BrandColorMap | undefined {
  if (brandColor && brandColor.startsWith(Constants.EXTERNAL_REF_KEY)) {
    const brandColorId = brandColor.split(Constants.ID_START_KEY)[1];

    return colors.find(color => color.get('id') === brandColorId);
  }

  return editorUtils.getBrandColor(colors, brandColor);
}

export function getBrandColorProperties(
  brandColor: Models.BrandColorMap,
  brandStyle?: Models.BrandStyleMap,
  colors?: Models.BrandColorsList,
): BrandColorProperties {

  let colorHEX = brandColor ? brandColor.get('HEX') : '';
  let colorName = brandColor ? brandColor.get('name') : '';
  let colorTint = brandColor ? brandColor.get('tint') : 0;

  if (!brandColor && brandStyle && colors) {
    const defaultBrandStyleColor = getDefaultColorFromBrandStyle(colors, getDesktopBrandStyles(brandStyle));
    const defaultBrandColor = getDefaultBrandStyle(defaultBrandStyleColor, colors);

    colorHEX = defaultBrandColor ? defaultBrandColor.get('HEX') : defaultBrandStyleColor;
    colorName = defaultBrandColor ? defaultBrandColor.get('name') : '';
    colorTint = defaultBrandColor ? defaultBrandColor.get('tint') : 0;
  }

  return {
    colorHEX,
    colorName,
    colorTint,
  };
}

export type GetBrandStylesProps = {
  relation: Models.LayeredRegularRelationMap<Models.TextRelationStyles>;
  brandStylesByRelationId: Immutable.Map<string, Models.BrandStylesMap>;
};

export function getBrandStyles(props: GetBrandStylesProps): Models.BrandStylesMap | undefined {
  const { brandStylesByRelationId, relation } = props;

  return brandStylesByRelationId.get(relation.get('id'));
}

export type GetColorsProps = {
  relation: Models.LayeredRegularRelationMap<Models.TextRelationStyles>;
  flatColorsByRelationId: Models.BrandColorsMap;
};

export function getColors(props: GetColorsProps): Models.BrandColorsList {
  const { flatColorsByRelationId, relation } = props;

  return flatColorsByRelationId.get(relation.get('id'));
}

export type GetFontsProps = {
  relation: Models.LayeredRegularRelationMap<Models.TextRelationStyles>;
  flatFontsByRelationId: Models.BrandFontsMap;
};

export function getFonts(props: GetFontsProps): Models.BrandFontsList {
  const { flatFontsByRelationId, relation } = props;

  return flatFontsByRelationId.get(relation.get('id'));
}
