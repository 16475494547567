import classNames from 'classnames';
import React from 'react';
import { TextAbbreviationJSON } from 'models';
import { intlGet } from 'utils/intlGet';
import styles from './styles.module.scss';

type Props = {
  abbreviation: TextAbbreviationJSON;
  onChange: () => void;
  onRemove: () => void;
};

const AbbreviationDetails: React.FunctionComponent<Props> = (props) => {
  const { abbreviation, onChange, onRemove } = props;

  return (
    <div className={classNames(styles.abbreviationContainer, 'abbreviation-details')}>
      <div className={styles.scroll}>
        <span className={styles.term}>{abbreviation.term}</span>
        <span className={styles.definition}>{abbreviation.definition}</span>
      </div>
      <div className={styles.buttons}>
        <button className={styles.button} onClick={onChange}>
          {intlGet('EditorToolbar.Abbreviation', 'Button.Change')}
        </button>
        <button className={styles.button} onClick={onRemove}>
          {intlGet('EditorToolbar.Abbreviation', 'Button.Remove')}
        </button>
      </div>
    </div>
  );
};

export default AbbreviationDetails;
