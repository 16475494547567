import React from 'react';
import css from '../styles.module.scss';

type Props = {
  text: string;
};

const SelectedText: React.FunctionComponent<Props> = (props) => {
  const { text } = props;

  return (
    <div className={css.term}>
      {text.replace(/\s\s+/g, ' ')}
    </div>
  );
};

export default SelectedText;
