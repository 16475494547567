import { wrapperOnMouseDownHack } from 'modules/draftjs';
import React from 'react';
import { ToolbarDropdownType } from 'components/EditorToolbar/constants';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { TitleWithNamespace as Title } from 'components/Toolbar/constants';
import { MultiCustomStyle } from 'const';
import FontFamily from 'containers/Toolbar/Dropdowns/FontFamily';
import * as Models from 'models';
import { intlGet } from 'utils/intlGet';
import styles from './styles.module.scss';

type Props = {
  activeDropdown: ToolbarDropdownType | null;
  activeFontFamily: Models.FontFamily;
  returnFocusToEditor(): void;
  setActiveDropdown(type: ToolbarDropdownType | null): void;
  setFontFamily(font: Models.BrandFontMap, charStyle: Models.CharacterStyleMap): void;
};

class FontFamilyControl extends React.PureComponent<Props> {
  private icon = React.createRef<HTMLDivElement>();

  componentDidMount(): void {
    document.addEventListener('click', this.closeDialog);
  }

  componentWillUnmount(): void {
    document?.removeEventListener('click', this.closeDialog);
  }

  private closeDialog = (event: MouseEvent): void => {
    const { activeDropdown, setActiveDropdown } = this.props;
    const isFontFamilyDropdownOpen = activeDropdown === ToolbarDropdownType.FONT_FAMILY;
    const isTargetChevron = this.icon.current?.contains(event.target as Node);

    if (!isTargetChevron && isFontFamilyDropdownOpen) {
      setActiveDropdown(null);
    }
  };

  private handleFontSelection = (font: Models.BrandFontMap, charStyle: Models.CharacterStyleMap): void => {
    const { setFontFamily, returnFocusToEditor } = this.props;

    setFontFamily(font, charStyle);
    returnFocusToEditor();
  };

  private handleMouseDownHack = (event: React.MouseEvent<HTMLDivElement>): void => {
    wrapperOnMouseDownHack(event);
    const { activeDropdown, setActiveDropdown } = this.props;

    setActiveDropdown(activeDropdown !== ToolbarDropdownType.FONT_FAMILY ? ToolbarDropdownType.FONT_FAMILY : null);
  };

  render() {
    const { activeDropdown, activeFontFamily } = this.props;
    const showDropdown = activeDropdown === ToolbarDropdownType.FONT_FAMILY;

    return (
      <div className={styles.FontFamilyControl} data-title={Title.FONT_FAMILY} >
        <div
          onMouseDown={this.handleMouseDownHack}
          ref={this.icon}
          className={styles.fontFamilyWrapper}
          data-tooltip={activeFontFamily.fontFamily || intlGet('EditorToolbar.Tooltip', 'FontFamily')}
        >
          <span className={styles.controlText}>{activeFontFamily.fontFamily || MultiCustomStyle.FONT_FAMILY}</span>
          <Icon type={IconType.CHEVRON_DOWN} size="badge" color={showDropdown ? 'primary' : 'secondary'} hoverColor="primary-hover" />
        </div>
        {
          showDropdown &&
          <FontFamily
            activeFontFamily={activeFontFamily}
            toggleFontFamily={this.handleFontSelection}
          />
        }
      </div>
    );
  }
}

export default FontFamilyControl;
