import { wrapperOnMouseDownHack } from 'modules/draftjs';
import React from 'react';
import { ToolbarDropdownType } from 'components/EditorToolbar/constants';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { TitleWithNamespace as Title } from 'components/Toolbar/constants';
import Dropdown from 'components/Toolbar/Shared/Dropdown';
import { HORIZONTAL_TEXT_ALIGNMENTS } from 'const';
import { TextHorizontalAlignmentType } from 'const/TextAlignment';
import { intlGet } from 'utils/intlGet';
import styles from './styles.module.scss';

type Props = {
  blockType: TextHorizontalAlignmentType;
  activeDropdown: ToolbarDropdownType | null;
  onToggle: (style: TextHorizontalAlignmentType) => void;
  setActiveDropdown: (type: ToolbarDropdownType | null) => void;
};

class TextHorizontalAlignmentControls extends React.PureComponent<Props> {
  private icon = React.createRef<HTMLDivElement>();

  componentDidMount(): void {
    document.addEventListener('click', this.closeDialog);
  }

  componentWillUnmount(): void {
    document?.removeEventListener('click', this.closeDialog);
  }

  private closeDialog = (event: MouseEvent): void => {
    const { activeDropdown, setActiveDropdown } = this.props;
    const isTextAlignDropdownOpen = activeDropdown === ToolbarDropdownType.TEXT_ALIGN_HORIZONTAL;
    const isTargetIcon = this.icon.current?.contains(event.target as Node);

    if (!isTargetIcon && isTextAlignDropdownOpen) {
      setActiveDropdown(null);
    }
  };

  private handleMouseDownHack = (event: React.MouseEvent<HTMLDivElement>): void => {
    wrapperOnMouseDownHack(event);
    const { activeDropdown, setActiveDropdown } = this.props;
    setActiveDropdown(activeDropdown !== ToolbarDropdownType.TEXT_ALIGN_HORIZONTAL ? ToolbarDropdownType.TEXT_ALIGN_HORIZONTAL : null);
  };

  render() {
    const { activeDropdown, blockType, onToggle } = this.props;
    const showDropdown = activeDropdown === ToolbarDropdownType.TEXT_ALIGN_HORIZONTAL;
    const currentAlignObj = HORIZONTAL_TEXT_ALIGNMENTS.find(({ type }) => type === blockType);

    return (
      <div className={styles.TextAlignmentControls} data-title={Title.TEXT_ALIGNMENT_HORIZONTAL}>
        <div
          className={styles.iconWrapper}
          onMouseDown={this.handleMouseDownHack}
          ref={this.icon}
          data-tooltip={intlGet('EditorToolbar.Tooltip', 'TextAlignmentHorizontal')}
        >
          <Icon
            type={currentAlignObj ? currentAlignObj.icon : IconType.TOOLBAR_LEFT}
            size="badge"
            color={showDropdown ? 'primary' : 'secondary'}
            hoverColor="primary-hover"
          />
        </div>
        {
          showDropdown &&
          <Dropdown className={styles.Dropdown}>
            {
              HORIZONTAL_TEXT_ALIGNMENTS.map(({ type, icon, title }) => (
                <div className={styles.item} key={title} onClick={onToggle.bind(null, type)} data-title={title}>
                  <Icon type={icon} size="badge" color={type === blockType ? 'primary' : 'secondary'} />
                  <span className={styles.hint}>{intlGet('EditorToolbar.TextAlignment.Horizontal', type as string)}</span>
                </div>
              ))
            }
          </Dropdown>
        }
      </div>
    );
  }
}

export default TextHorizontalAlignmentControls;
