import classNames from 'classnames';
import { CellAction, useCellActionsExecutor } from 'context/CellActions';
import { TextEditor, useDraftjsHandleDrop, wrapperOnMouseDownHackStrict } from 'modules/draftjs';
import React, { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import EditorToolbar from 'components/EditorToolbar';
import * as editorUtils from 'utils/editor';
import { intlGet } from 'utils/intlGet';
import { areThereHorizontalNeighbors } from 'utils/rowsHeight/areThereHorizontalNeighbors';
import UndoPortal from './components/UndoPortal';
import useBrandProps from './hooks/useBrandProps';
import useBrandStyleChange from './hooks/useBrandStyleChange';
import useCell from './hooks/useCell';
import useEditor from './hooks/useEditor';
import useEditorAnchors from './hooks/useEditorAnchors';
import { useEditorBlockStyleFn } from './hooks/useEditorBlockStyleFn';
import useResizeObserver from './hooks/useResizeObserver';
import useSave from './hooks/useSave';
import useStyles from './hooks/useStyles';
import useUndo, { cancelUndoStack } from './hooks/useUndo';
import useUpdate from './hooks/useUpdate';
import { TextProps } from './models';
import css from './styles.module.scss';
import { getInitialEditorOtherState, getInitialEditorState, setColorForListBullets } from './utils/editor';
import { getInitialStyles, stylesToCSS } from './utils/styles';
import { wrapEditorSettersWithUndoMiddleware, wrapStylesSettersWithUndoMiddleware } from './utils/undo';

export function getTextDOMId(relationId: string): string {
  return `text-cell-${relationId}`;
}

const Text: React.FunctionComponent<TextProps> = (props) => {
  const {
    layoutId,
    projectType,
    relation,
    sectionStyles,
    images,
    placeholderMinHeight,
    shrinkable,
    editMode,
    notEditable,
    isDraggingAsset,
    layoutRelations,
    activeLayer,
  } = props;

  const wrapperRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const brandProps = useBrandProps(props);

  const source = relation.getIn(['styles', activeLayer]) || new Map();
  const stylesHook = useStyles(
    () => getInitialStyles(source, brandProps),
  );

  const onBeforeEditorStateChangeRef = useRef<(() => void)>();

  const editorHook = useEditor(
    props,
    brandProps,
    stylesHook.styles.brandStyle,
    stylesHook.stylesSetters.brandStyleChanged,
    () => getInitialEditorState(props, brandProps),
    () => getInitialEditorOtherState(props, brandProps),
    onBeforeEditorStateChangeRef.current,
  );

  const cellHook = useCell(
    props,
    wrapperRef,
    editorHook,
    stylesHook.styles,
  );
  const { brandStyleChanged: setBrandStyleChanged } = stylesHook.stylesSetters;
  const { toggleAutoFitContent, props: { isAutoFitContent } } = cellHook;
  const toggleAutoFitContentWithBrandStyleChange = useCallback(() => {
    toggleAutoFitContent();
    if (isAutoFitContent) {
      setBrandStyleChanged();
    }
  }, [setBrandStyleChanged, toggleAutoFitContent, isAutoFitContent]);

  const storeText = useSave(
    props,
    brandProps,
    editorHook,
    cellHook,
    stylesHook,
  );

  const undoHook = useUndo(editorHook, stylesHook, cellHook);
  onBeforeEditorStateChangeRef.current = undoHook.updateUndoRedoBeforeChange;

  const editorAnchors = useEditorAnchors(editorHook.editorRef, props);
  const editorDropHandler = useDraftjsHandleDrop(
    props,
    editorHook.editorState,
    editorHook.setEditorState,
    editorHook.addOperation,
    storeText,
  );
  const editorBlockStyleFn = useEditorBlockStyleFn(projectType);

  const brandStyleChangeHook = useBrandStyleChange(
    projectType,
    editorHook,
    brandProps,
    cellHook,
    stylesHook,
  );

  useResizeObserver(wrapperRef.current, cellHook.resetHeight);
  useEffect(() => {
    const wrapper = wrapperRef.current;
    wrapper.addEventListener('beforeinput', cancelUndoStack);

    return (): void => wrapper.removeEventListener('beforeinput', cancelUndoStack);
  }, [wrapperRef.current]);

  useEffect(() => {
    editorAnchors.setAnchors(editorHook.editorState);
    setColorForListBullets(
      editorHook.editorRef.current,
      editorHook.editorState,
      stylesHook.styles.brandStyle,
      brandProps.colors,
    );
  }, []);

  useUpdate(
    props,
    editorHook.editorRef,
    editorHook,
    brandProps,
    stylesHook,
    cellHook,
    brandStyleChangeHook,
    editorAnchors,
    undoHook,
    storeText,
  );

  const { undoStackMiddleware } = undoHook;
  const stylesSettersWithUndo = wrapStylesSettersWithUndoMiddleware(stylesHook.stylesSetters, undoStackMiddleware);
  const editorSettersWithUndo = wrapEditorSettersWithUndoMiddleware(editorHook.setters, undoStackMiddleware);

  const { colors, fonts } = brandProps;
  if (!colors || !fonts) {
    return null;
  }

  const { editorState } = editorHook;
  const isMultiColumn = areThereHorizontalNeighbors(relation.get('id'), layoutRelations);
  const isAutoHeight = editorUtils.hasToken(editorState) && !isMultiColumn;
  const notShowPlaceholder = notEditable || editMode || editorHook.hasTextContent();

  useCellActionsExecutor(CellAction.SET_CURSOR_ON_ABBREVIATION, (abbreviationId, abbreviationNumber) => {
    const newState = editorUtils.selectAbbreviation(editorState, abbreviationId, abbreviationNumber);
    if (!newState) {
      return false;
    }
    editorHook.setEditorState(newState);

    return true;
  });

  useCellActionsExecutor(CellAction.APPLY_SELECTION, (
    blockKey: string,
    start: number,
    end: number,
  ) => {
    editorHook.setEditorState(editorUtils.applySelection(editorState, blockKey, start, end));
  });

  return (
    <div
      role='presentation'
      className={classNames(css.Text, {
        [css.severalColumns]: cellHook.props.cellsCount > 1,
        [css.noMinHeight]: editorHook.hasTextContent() || editMode || shrinkable || cellHook.props.cellHeight,
        [css.fullHeight]: cellHook.props.isLastCell || cellHook.props.cellHeight,
      })}
      style={stylesToCSS(stylesHook.styles, { images, placeholderMinHeight })}
      onMouseDown={wrapperOnMouseDownHackStrict}
    >
      {editMode && (
        <>
          <UndoPortal
            undo={undoHook.undo}
            redo={undoHook.redo}
            isRedoDisabled={undoHook.isRedoDisabled}
            isUndoDisabled={undoHook.isUndoDisabled}
          />
          <EditorToolbar
            layoutId={layoutId}
            projectType={projectType}
            returnFocusToEditor={editorHook.returnFocusToEditor}
            editorProps={editorHook.props}
            editorSetters={editorSettersWithUndo}
            styles={stylesHook.styles}
            stylesSetters={stylesSettersWithUndo}
            cellProps={cellHook.props}
            toggleAutoFitContent={toggleAutoFitContentWithBrandStyleChange}
            toggleCellHeight={undoStackMiddleware(cellHook.toggleCellHeight, 'cellHeight')}
            toggleColumnWidth={undoStackMiddleware(cellHook.toggleCellWidth, 'cellWidth')}
            isAutoHeight={isAutoHeight}
            beforeAutoFitMouseDown={undoHook.updateUndoRedoBeforeChange}
            setBrandStyle={undoStackMiddleware(brandStyleChangeHook.setBrandStyle, 'brandStyle')}
            sectionStyles={sectionStyles}
          />
        </>
      )}
      <TextEditor
        id={getTextDOMId(relation.get('id'))}
        ref={editorHook.editorRef}
        wrapperRef={wrapperRef}
        editorState={editorState}
        onEditorChange={editorHook.onEditorChange}
        returnFocusToEditor={editorHook.returnFocusToEditor}
        setEditorState={editorHook.setEditorState}
        setEditorStateAndOperations={editorHook.setEditorStateAndOperations}
        addOperation={editorHook.addOperation}
        editMode={editMode}
        blockStyleFn={editorBlockStyleFn}
        handleDrop={editorDropHandler}
        placeholder={notShowPlaceholder ? undefined : intlGet('Artboard.Layout.Text', 'Hint')}
        brandProps={brandProps}
        isDraggingAsset={isDraggingAsset}
        anchors={editorAnchors.anchors}
        undoStackMiddleware={undoStackMiddleware}
        undo={undoHook.undo}
        redo={undoHook.redo}
        fillUndoStackIfEmpty={undoHook.fillUndoStackIfEmpty}
      />
    </div>
  );
};


export default Text;
