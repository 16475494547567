import { ScreensMap } from 'models';
import { Schemas } from 'services/ArtboardConverter/models';
import { removeScriptTag } from 'utils/removeScriptTag';
import { SegmentType } from '../constants';
import { getLinks } from '../utils/getLinks';
import { createPart } from './createPart';

export const createSegment = ({
  parts = [],
  type = SegmentType.TEXT,
  entityData,
  surfaces,
  ...args
} = {} as Partial<{ entityData: { url: string } } & { surfaces: ScreensMap } & Schemas.Segment>): Schemas.Segment => {
  let links = {} as Schemas.Links;
  if (entityData && entityData.url) {
    links = getLinks(entityData.url, surfaces);
    if (links.href) {
      links.href = removeScriptTag(links.href);
    }
  }

  return {
    parts: parts.map(createPart),
    type,
    ...links,
    ...args,
  } as Schemas.Segment;
};
