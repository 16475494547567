import * as Constants from 'const';
import * as Models from 'models';
import { isImmutable, toImmutable } from 'utils/immutable';

export function getDesktopBrandStyles(brandStyle: Models.BrandStyleMap | Models.BrandStyle): Models.BrandStyleObjMap {
  const convertedBrandStyle = isImmutable(brandStyle) ? brandStyle : toImmutable(brandStyle);

  return convertedBrandStyle
    .get('breakpoints')
    .find(breakpoint => breakpoint.get('type') === Constants.DESKTOP_BREAKPOINT)
    .get('style');
}
