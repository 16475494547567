import TextAbbreviationControl from 'modules/Abbreviations/components/ToolbarAbbreviation/TextAbbreviationControl';
import React, { useState } from 'react';
import { TextEditorProps, TextEditorSetters } from 'components/ArtboardAssets/Text/hooks/useEditor';
import { CellProps } from 'components/ArtboardAssets/Text/utils/cell';
import { Styles, StylesSetters } from 'components/ArtboardAssets/Text/utils/styles';
import Subtitle from 'components/Toolbar/BaseToolbar/components/Subtitle';
import { HOVER_HINT } from 'components/Toolbar/constants';
import BackgroundImage from 'components/Toolbar/Controls/BackgroundImage';
import BorderControl from 'components/Toolbar/Controls/Border';
import BorderRadius from 'components/Toolbar/Controls/BorderRadius';
import FillColor from 'components/Toolbar/Controls/FillColor';
import FontColor from 'components/Toolbar/Controls/FontColor';
import FontSize from 'components/Toolbar/Controls/FontSize';
import Link from 'components/Toolbar/Controls/Link';
import Padding from 'components/Toolbar/Controls/Padding';
import TextLineHeight from 'components/Toolbar/Controls/TextLineHeight';
import TextVerticalAlignment from 'components/Toolbar/Controls/TextVerticalAlignment';
import AutoFitContentToggle from 'components/Toolbar/Shared/AutoFitContentToggle';
import ControlGroup from 'components/Toolbar/Shared/ControlGroup';
import HeightWidth from 'components/Toolbar/Shared/HeightWidth';
import * as Constants from 'const';
import BaseToolbar from 'containers/Toolbar/BaseToolbar';
import * as Models from 'models';
import { intlGet } from 'utils/intlGet';
import { ToolbarDropdownType } from './constants';
import BrandStyleControl from './controls/BrandStyleControl';
import BulletedListControl from './controls/BulletedListControl';
import CharStyleControl from './controls/CharStyleControl';
import FontFamilyControl from './controls/FontFamilyControl';
import ScriptControl from './controls/ScriptControl';
import TextHorizontalAlignmentControls from './controls/TextHorizontalAlignmentControls';
import WrapControl from './controls/WrapControl';
import css from './styles.module.scss';

type Props = {
  layoutId: string;
  projectType: Constants.ProjectType;
  returnFocusToEditor: () => void;

  editorProps: TextEditorProps;
  editorSetters: TextEditorSetters;

  styles: Styles;
  stylesSetters: StylesSetters;

  cellProps: CellProps;
  toggleColumnWidth: (newWidth: number) => number;
  toggleCellHeight: (newHeight: number) => void;
  toggleAutoFitContent: (value?: boolean) => void;
  isAutoHeight: boolean;
  beforeAutoFitMouseDown: () => void;

  sectionStyles: Models.MasterScreen.SectionStylesMap;
  setBrandStyle: (style: Models.BrandStyleMap) => void;
};


const EditorToolbar: React.FunctionComponent<Props> = (props) => {
  const {
    layoutId,
    projectType,
    returnFocusToEditor,
    isAutoHeight,
    editorProps,
    editorSetters,
    styles,
    stylesSetters,
    sectionStyles,
    setBrandStyle,
    cellProps,
    toggleCellHeight,
    toggleColumnWidth,
    toggleAutoFitContent,
    beforeAutoFitMouseDown,
  } = props;

  const { allowBackgroundOpacity, allowBackgroundImage } = Constants.ProjectsConfig[projectType];
  const [activeDropdown, setActiveDropdown] = useState<ToolbarDropdownType | null>(null);

  return (
    <BaseToolbar layoutId={layoutId}>
      <Subtitle title={intlGet('EditorToolbar.Asset', 'Cell')} />
      <ControlGroup>
        <HeightWidth
          isAutoHeight={isAutoHeight}
          isAutoFitContent={cellProps.isAutoFitContent}
          height={cellProps.cellHeight}
          maxHeight={cellProps.maxCellHeight}
          minHeight={cellProps.minCellHeight}
          toggleHeight={toggleCellHeight}
          disableCellWidthEditing={cellProps.disableCellWidthEditing}
          isIncreasingWidthDisabled={cellProps.isIncreasingWidthDisabled}
          minWidth={Constants.MinCellWidth.TEXT}
          width={cellProps.cellWidth}
          toggleColumnWidth={toggleColumnWidth}
          toggleAutoFitContent={toggleAutoFitContent}
        />
        <AutoFitContentToggle
          isAutoFitContent={cellProps.isAutoFitContent}
          isAutoHeight={isAutoHeight}
          toggleAutoFitContent={toggleAutoFitContent}
          beforeMouseDown={beforeAutoFitMouseDown}
        />
      </ControlGroup>
      <Subtitle title={intlGet('EditorToolbar.Asset', 'Text')} />
      <ControlGroup>
        <BrandStyleControl
          setActiveDropdown={setActiveDropdown}
          activeDropdown={activeDropdown}
          returnFocusToEditor={returnFocusToEditor}
          brandStyle={styles.brandStyle}
          brandStyleChanged={styles.brandStyleChanged}
          setStyle={setBrandStyle}
          sectionStyles={sectionStyles}
        />
      </ControlGroup>
      <ControlGroup>
        <FontFamilyControl
          returnFocusToEditor={returnFocusToEditor}
          setActiveDropdown={setActiveDropdown}
          activeDropdown={activeDropdown}
          setFontFamily={(font, charStyle): void => {
            editorSetters.fontFamily(font, charStyle);
            returnFocusToEditor();
          }}
          activeFontFamily={editorProps.fontFamily}
        />
      </ControlGroup>
      <ControlGroup>
        <FontSize
          fontSize={editorProps.fontSize}
          toggleFontSize={editorSetters.fontSize}
          returnFocusToEditor={returnFocusToEditor}
        />
      </ControlGroup>
      <ControlGroup>
        <CharStyleControl
          activeStyle={editorProps.inlineStyle}
          onToggle={(value): void => {
            editorSetters.inlineStyle(value);
            returnFocusToEditor();
          }}
        />
        <ScriptControl
          type={editorProps.scriptStyle}
          onToggle={(value): void => {
            editorSetters.scriptStyle(value);
            returnFocusToEditor();
          }}
        />
        <FontColor
          color={editorProps.fontColor}
          toggleFontColor={(value): void => {
            editorSetters.fontColor(value);
            returnFocusToEditor();
          }}
          defaultColor={Constants.DefaultCustomStyle.FONT_COLOR as string}
        />
      </ControlGroup>
      <ControlGroup>
        <TextHorizontalAlignmentControls
          blockType={editorProps.blockType}
          activeDropdown={activeDropdown}
          setActiveDropdown={setActiveDropdown}
          onToggle={(value): void => {
            editorSetters.blockType(value);
            returnFocusToEditor();
          }}
        />
        <TextVerticalAlignment
          alignment={styles.verticalAlignment}
          toggleAlignment={stylesSetters.verticalAlignment}
        />
        <TextLineHeight
          blockLineHeight={editorProps.blockLineHeight}
          setLineHeight={editorSetters.blockLineHeight}
        />
        <BulletedListControl
          active={editorProps.blockType === Constants.TextHorizontalAlignmentType.UNORDERED_LIST}
          color={editorProps.bulletColor}
          defaultColor={Constants.DefaultCustomStyle.FONT_COLOR as string}
          onToggle={(): void => {
            editorSetters.blockType(Constants.TextHorizontalAlignmentType.UNORDERED_LIST);
            returnFocusToEditor();
          }}
          toggleBulletColor={(value): void => {
            editorSetters.bulletColor(value);
            returnFocusToEditor();
          }}
        />
        <WrapControl
          isNoWrapActive={editorProps.textNoWrap}
          onToggle={editorSetters.textNoWrap}
        />
      </ControlGroup>
      <ControlGroup>
        <Link
          isDisabled={!editorProps.linkApplicable}
          link={editorProps.link || ''}
          toggleLink={editorSetters.link}
          returnFocusToEditor={returnFocusToEditor}
        />
        <TextAbbreviationControl
          selectedText={editorProps.abbreviationTerm}
          abbreviationId={editorProps.abbreviationID}
          onChange={editorSetters.abbreviationID}
          returnFocusToEditor={returnFocusToEditor}
        />
        <Padding
          padding={styles.padding}
          togglePadding={stylesSetters.padding}
          className='mr-0'
          returnFocusToEditor={returnFocusToEditor}
        />
      </ControlGroup>
      <ControlGroup>
        <BorderControl
          border={styles.border}
          toggleBorder={stylesSetters.border}
          returnFocusToEditor={returnFocusToEditor}
        />
        <FillColor
          activeColor={styles.backgroundColor}
          toggleColor={stylesSetters.backgroundColor}
          opacity={styles.backgroundColorOpacity}
          toggleOpacity={allowBackgroundOpacity ? stylesSetters.backgroundColorOpacity : undefined}
          gradient={styles.backgroundGradient}
          toggleGradient={stylesSetters.backgroundGradient}
          returnFocusToEditor={returnFocusToEditor}
        />
        {allowBackgroundImage &&
          <BackgroundImage
            backgroundImage={styles.backgroundImage}
            toggleBackgroundImage={stylesSetters.backgroundImage}
            returnFocusToEditor={returnFocusToEditor}
          />
        }
        <BorderRadius
          borderRadius={styles.borderRadius}
          title={HOVER_HINT.CELL}
          dropdownWarningMessage={intlGet('EditorToolbar.Tooltip', 'WarningMessage')}
          toggleBorderRadius={stylesSetters.borderRadius}
          dropdownWarningIconClassName={css.lastDropdownWarningIcon}
        />
      </ControlGroup>
    </BaseToolbar>
  );
};

export default EditorToolbar;
