import Editor from '@draft-js-plugins/editor';
import Draft from 'draft-js';
import React, { RefObject, forwardRef, useImperativeHandle, useRef } from 'react';
import * as Constants from 'const';
import { UndoRedoMiddleware } from 'hooks/useUndoRedo';
import * as Models from 'models';
import { undoRedoKeyBindings } from '../helpers/undoRedoKeyBindings';
import { useCustomStyleFn } from '../hooks/useCustomStyleFn';
import { useHandleBeforeInputWithFormat } from '../hooks/useHandleBeforeInputWithFormat';
import { useHandleKeyCommand } from '../hooks/useHandleKeyCommand';
import { useHandlePastedText } from '../hooks/useHandlePastedText';
import { useHandleReturn } from '../hooks/useHandleReturn';
import { useOnTab } from '../hooks/useOnTab';
import { useOnWrapperClick } from '../hooks/useOnWrapperClick';

type Props = {
  id: string;
  wrapperRef: RefObject<HTMLDivElement>;
  editorState: Draft.EditorState;
  onEditorChange: (state: Draft.EditorState) => void;
  returnFocusToEditor: () => void;
  setEditorState: (state: Draft.EditorState) => void;
  setEditorStateAndOperations: (state: Draft.EditorState) => void;
  blockStyleFn:(block: Draft.ContentBlock) => string;
  handleDrop: (selection: Draft.SelectionState) => Draft.DraftHandleValue;
  addOperation: (operation: Models.DraftEditorOperation) => void;
  editMode: boolean;
  placeholder: string | undefined;
  brandProps: {
    colors: Models.BrandColorsList;
    fonts: Models.BrandFontsList;
  };
  isDraggingAsset: unknown;
  anchors: JSX.Element | null;
  undoStackMiddleware: UndoRedoMiddleware<unknown>;
  fillUndoStackIfEmpty: () => void;
  undo: () => boolean;
  redo: () => boolean;
};

export const TextEditor = forwardRef<Editor, Props>((props, ref) => {
  const {
    id,
    wrapperRef,
    editorState,
    onEditorChange,
    returnFocusToEditor,
    addOperation,
    setEditorState,
    setEditorStateAndOperations,
    blockStyleFn,
    handleDrop,
    editMode,
    placeholder,
    brandProps,
    isDraggingAsset,
    anchors,
    undoStackMiddleware,
    fillUndoStackIfEmpty,
    undo,
    redo,
  } = props;

  const editorRef = useRef<Editor>(null);
  useImperativeHandle(ref, () => editorRef.current as Editor);

  const editorStateRef = useRef(editorState);
  editorStateRef.current = editorState;

  const editModeRef = useRef(editMode);
  editModeRef.current = editMode;

  const onWrapperClick = useOnWrapperClick(editorRef, editMode, returnFocusToEditor);
  const onWrapperKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === Constants.KeyboardKey.PAGE_UP || event.key === Constants.KeyboardKey.PAGE_DOWN) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const editorCustomStyleFn = useCustomStyleFn(brandProps);
  const handleBeforeInput = useHandleBeforeInputWithFormat(editorState, onEditorChange, fillUndoStackIfEmpty);
  const handleKeyCommand = useHandleKeyCommand(fillUndoStackIfEmpty, undo, redo);
  const handlePastedText = useHandlePastedText(editMode, editorState, setEditorState, addOperation);
  const handleReturn = useHandleReturn(editorState, onEditorChange);
  const onTab = useOnTab(editorState, setEditorStateAndOperations);

  return (
    <div
      role='presentation'
      id={id}
      onClick={onWrapperClick}
      onKeyDown={onWrapperKeyDown}
      ref={wrapperRef}
    >
      <Editor
        editorState={editorState}
        plugins={Constants.DraftEditorPlugins}
        blockStyleFn={blockStyleFn}
        customStyleFn={editorCustomStyleFn}
        handleBeforeInput={handleBeforeInput}
        handleKeyCommand={handleKeyCommand}
        handlePastedText={undoStackMiddleware(handlePastedText)}
        handleReturn={undoStackMiddleware(handleReturn)}
        handleDrop={handleDrop}
        keyBindingFn={undoRedoKeyBindings}
        onChange={onEditorChange}
        onTab={onTab}
        placeholder={placeholder}
        readOnly={!editMode && !isDraggingAsset}
        ref={editorRef}
        stripPastedStyles={true}
      />
      {anchors}
    </div>
  );
});
