import { BoxPropertySide } from 'const';
import * as Models from 'models';
import { getValue, getValues } from 'utils/getter';

export const getExtraWidth = (styles: Models.CommonStyles | Models.CommonStylesMap): number => {
  const { border, padding } = getValues(styles, ['border', 'padding']);

  const paddingValues = getValues(padding, [BoxPropertySide.LEFT, BoxPropertySide.RIGHT]);
  const paddingLeft = paddingValues[BoxPropertySide.LEFT];
  const paddingRight = paddingValues[BoxPropertySide.RIGHT];

  const borderWidth = getValue(border, 'width');
  const borderValues = getValues(borderWidth, [BoxPropertySide.LEFT, BoxPropertySide.RIGHT]);
  const borderLeft = borderValues[BoxPropertySide.LEFT];
  const borderRight = borderValues[BoxPropertySide.RIGHT];

  return borderRight + borderLeft + paddingRight + paddingLeft;
};
