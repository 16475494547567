import { DraftEntity } from 'const';
import { SegmentType } from '../constants';

/**
 * Return TEXT type ALWAYS (even Draft Entity is REFERENCE) except LINK Draft Entity type provided
 */
export function getSegmentType(entityKey: string, currentContent: Draft.ContentState): SegmentType {
  const entityType = entityKey && currentContent.getEntity(entityKey).getType();

  switch (entityType) {
    case DraftEntity.LINK: return SegmentType.LINK;
    case DraftEntity.NO_WRAP: return SegmentType.NO_WRAP;
    default: return SegmentType.TEXT;
  }
}
