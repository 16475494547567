import { TextAbbreviationJSON } from 'models';
import { cleanUpText } from 'utils/cleanUpText';

export function abbreviationsListToHTML(
  list: TextAbbreviationJSON[],
): string {
  if (!list.length) {
    return '';
  }

  return list
    .map((item) => {
      const term = cleanUpText(item?.term);
      const definition = cleanUpText(item?.definition);

      return `<span>${term} = ${definition}</span>`;
    })
    .join('; ')
    .concat('.');
}
