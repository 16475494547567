import Immutable from 'immutable';
import { useCallback, useMemo, useState } from 'react';
import { IMap } from 'typings/DeepIMap';
import * as Constants from 'const';
import * as Models from 'models';
import { getChangedStylesToBeKept } from 'utils/styles/getStylesToBeKept';
import { getBrandStyles, getColors, getFonts } from '../utils/brand';
import { getPrioritizedRelation } from '../utils/relation';
import { Styles, StylesSetters, stylesFromSource } from '../utils/styles';

type EnsureStylesToByKeptProps = {
  activeLayer: Constants.Layer;
  relation: Models.LayeredRegularRelationMap<Models.TextRelationStyles>;
  brandStylesByRelationId: Immutable.Map<string, Models.BrandStylesMap>;
  flatColorsByRelationId: Models.BrandColorsMap;
  flatFontsByRelationId: Models.BrandFontsMap;
};

type StylesHook = {
  styles: Styles;
  stylesSetters: StylesSetters;
  setRawStyles: (styles: Partial<Styles>) => void;
  resetStyles: (
    source: IMap<Models.TextRelationStyles>,
    brandStyles: Models.BrandStylesMap,
    brandStyleId: string,
    brandStyleChanged: boolean,
    colors: Models.BrandColorsList,
  ) => void;
  ensureStylesToBeKept: (props: EnsureStylesToByKeptProps) => void;
};

export default function useStyles(initStyles: () => Styles): StylesHook {
  const [styles, setStyles] = useState<Styles>(initStyles);

  const setRawStyles = useCallback(
    (values: Partial<Styles>) => setStyles(prev => ({ ...prev, ...values })),
    [],
  );

  const resetStyles = useCallback((
    source: IMap<Models.TextRelationStyles>,
    brandStyles: Models.BrandStylesMap,
    brandStyleId: string,
    brandStyleChanged: boolean,
    colors: Models.BrandColorsList,
  ) => setStyles({
    ...stylesFromSource(source, brandStyles, colors),
    brandStyle: brandStyles.get(brandStyleId),
    brandStyleChanged,
  })
  , []);

  const setters = useMemo((): StylesSetters => {
    return {
      backgroundColor: color => setStyles(prev => ({
        ...prev,
        backgroundColor: color,
        backgroundGradient: undefined,
        brandStyleChanged: true,
      })),
      backgroundColorOpacity: backgroundColorOpacity => setStyles(prev => ({
        ...prev,
        backgroundColorOpacity,
        brandStyleChanged: true,
      })),
      backgroundGradient: (gradient, backupColor) => setStyles(prev => ({
        ...prev,
        backgroundColor: backupColor,
        backgroundGradient: gradient,
        brandStyleChanged: true,
      })),
      backgroundImage: backgroundImage => setStyles(prev => ({ ...prev, backgroundImage })),
      border: border => setStyles(prev => ({ ...prev, border })),
      borderRadius: borderRadius => setStyles(prev => ({ ...prev, borderRadius })),
      brandStyle: brandStyle => setStyles(prev => ({ ...prev, brandStyle })),
      brandStyleChanged: (value = true) => setStyles(prev => ({ ...prev, brandStyleChanged: value })),
      padding: padding=> setStyles(prev => ({
        ...prev,
        padding,
        brandStyleChanged: true,
      })),
      verticalAlignment: verticalAlignment => setStyles(prev => ({
        ...prev,
        verticalAlignment,
        brandStyleChanged: true,
      })),
    };
  }, []);

  // TODO: it looks like overhead
  const ensureStylesToBeKept = useCallback((props: EnsureStylesToByKeptProps): void => {
    const { activeLayer, relation } = props;
    const brandStyles = getBrandStyles(props);
    const brandColors = getColors(props);
    const fonts = getFonts(props);
    const newStyles = stylesFromSource(
      relation.getIn(['styles', activeLayer]),
      brandStyles,
      brandColors,
    );

    const {
      backgroundColor,
      backgroundColorTint,
      backgroundColorOpacity,
      backgroundGradient,
      backgroundImage,
      border,
      padding,
    } = getChangedStylesToBeKept(getPrioritizedRelation(props), brandStyles, brandColors, fonts);

    if (backgroundGradient) {
      setters.backgroundGradient(newStyles.backgroundGradient, newStyles.backgroundColor);
    } else if (backgroundColor || backgroundColorTint) {
      setters.backgroundColor(newStyles.backgroundColor);
    }

    if (backgroundColorOpacity) {
      setters.backgroundColorOpacity(backgroundColorOpacity);
    }

    if (backgroundImage) {
      setters.backgroundImage(newStyles.backgroundImage);
    }

    if (border) {
      setters.border(newStyles.border);
    }

    if (padding) {
      setters.padding(newStyles.padding);
    }
  }, [setters]);

  return {
    styles,
    stylesSetters: setters,
    setRawStyles,
    resetStyles,
    ensureStylesToBeKept,
  };
}
