import { CSSProperties } from 'react';
import { IMap } from 'typings/DeepIMap';
import * as Constants from 'const';
import { DefaultTextBrandStyle } from 'const/Styles';
import * as Models from 'models';
import { getTextStylesFromBrandStyle } from 'utils/brandStyles';
import { brandBorderFromSource, brandBorderToSource, colorFromSource, colorToSource, colorToSourceTint } from 'utils/converters';
import { brandColorGradientFromSource, brandColorGradientToSource } from 'utils/converters/colorGradient';
import { getBorderCSSProperties, getPadding, getBorderRadius, getMinHeight } from 'utils/styles';
import { getCSSBackgroundBrand } from 'utils/styles/getBackground';
import { BrandProps } from '../hooks/useBrandProps';

export type Styles = {
  backgroundColor?: Models.BrandColorMap;
  backgroundColorOpacity: number;
  backgroundGradient?: Models.BrandColorGradientMap;
  backgroundImage?: Models.BackgroundImageMap;
  border?: Models.BorderMap;
  borderRadius?: Models.BorderRadiusMap;
  brandStyle?: Models.BrandStyleMap;
  brandStyleChanged: boolean;
  padding: Models.PaddingMap;
  verticalAlignment: Constants.AssetAlignmentType;
};

type SpecialSetters = {
  backgroundGradient: (gradient?: Models.BrandColorGradientMap, backupColor?: Models.BrandColorMap) => void;
  brandStyleChanged: (brandStyleChanged?: boolean) => void;
};

export type StylesSetters = {
  [K in keyof Omit<Styles, keyof SpecialSetters>]-?: (value: Styles[K]) => void;
} & {
  [K2 in keyof SpecialSetters as SpecialSetters[K2] extends never ? never : K2]: SpecialSetters[K2];
};

export function stylesFromSource(
  source: IMap<Models.TextRelationStyles>,
  brandStyles: Models.BrandStylesMap,
  colors: Models.BrandColorsList,
): Styles | null {

  if (!source) {
    return null;
  }

  const brandStyleId = source.get('brandStyleId');

  return {
    backgroundColor: colorFromSource(colors, source.get('backgroundColor'), source.get('backgroundColorTint')),
    backgroundColorOpacity: source.get('backgroundColorOpacity'),
    backgroundGradient: brandColorGradientFromSource(colors, source.get('backgroundGradient')),
    backgroundImage: source.get('backgroundImage'),
    border: brandBorderFromSource(colors, source.get('border')),
    borderRadius: source.get('borderRadius'),
    brandStyle: brandStyles.get(brandStyleId),
    brandStyleChanged: source.get('brandStyleChanged'),
    padding: source.get('padding'),
    verticalAlignment: source.getIn(['alignment', 'vertical']),
  };
}

export function stylesToSource(
  styles: Styles,
  source: IMap<Models.TextRelationStyles>,
): IMap<Models.TextRelationStyles> {
  return source.withMutations(values => values
    .set('backgroundColor', colorToSource(styles.backgroundColor))
    .set('backgroundColorTint', colorToSourceTint(styles.backgroundColor))
    .set('backgroundColorOpacity', styles.backgroundColorOpacity)
    .set('backgroundGradient', brandColorGradientToSource(styles.backgroundGradient))
    .set('backgroundImage', styles.backgroundImage)
    .set('border', brandBorderToSource(styles.border))
    .set('borderRadius', styles.borderRadius)
    .set('brandStyleChanged', styles.brandStyleChanged)
    .set('brandStyleId', styles.brandStyle?.get('id'))
    .set('padding', styles.padding)
    .setIn(['alignment', 'vertical'], styles.verticalAlignment),
  );
}

type CSSContext = {
  images: Models.CombinedDocumentsMap;
  placeholderMinHeight?: number;
};

export function stylesToCSS(styles: Styles, context: CSSContext): CSSProperties {
  const { images, placeholderMinHeight } = context;
  const aligment = Constants.TEXT_VERTICAL_ALIGNMENTS.find(item => item.type === styles.verticalAlignment)?.style;

  const props: CSSProperties = {
    ...getBorderCSSProperties(styles.border),
    ...getPadding(styles.padding),
    ...getCSSBackgroundBrand(
      styles.backgroundColor,
      styles.backgroundColorOpacity,
      styles.backgroundGradient,
      styles.backgroundImage,
      images,
    ),
    ...getBorderRadius(styles.borderRadius),
    ...((aligment as CSSProperties) || {}),
    ...getMinHeight(placeholderMinHeight),
  };

  return props;
}

export function getInitialStyles(
  source: IMap<Models.TextRelationStyles>,
  brandProps: BrandProps,
): Styles {
  const { colors, fonts, brandStyles } = brandProps;
  const styles = stylesFromSource(source, brandStyles, colors);

  // revise brand style logic (the same in Artboard converter services/ArtboardConverter)
  if (!styles.brandStyleChanged) {
    if (!styles.brandStyle) {
      styles.brandStyle = brandStyles.get(DefaultTextBrandStyle);
    }
    const textStyles = getTextStylesFromBrandStyle(styles.brandStyle, colors, fonts);
    styles.backgroundColor = textStyles.backgroundColor;
    styles.backgroundGradient = undefined;
    styles.padding = textStyles.padding;
    styles.verticalAlignment = textStyles.verticalAlignment;
  }

  return styles;
}
