import Draft from 'draft-js';
import { getBrandColorProperties } from 'components/ArtboardAssets/Text/utils/brand';
import * as Constants from 'const';
import * as Models from 'models';
import * as editorUtils from 'utils/editor';
import * as inlineStyles from 'utils/inlineStyles';

export function getFontColor<T extends boolean | undefined>(
  isEnabled: T,
  editorState: Draft.EditorState,
  colors: T extends true ? Models.BrandColorsList : unknown,
): T extends true ? Models.BrandColorMap | undefined : undefined {
  if (!isEnabled) {
    return undefined;
  }

  const colorHex = editorUtils.getStyleToDisplay(editorState, Constants.StylePrefix.FONT_COLOR) || '';
  const colorTint = editorUtils.getStyleToDisplay(editorState, Constants.StylePrefix.FONT_COLOR_TINT) || 0;
  const colorName = editorUtils.getStyleToDisplay(editorState, Constants.StylePrefix.FONT_COLOR_NAME) || '';
  const fontColor = editorUtils.getBrandColor(
    colors as Models.BrandColorsList,
    colorName || colorHex,
    colorTint,
  );

  return fontColor as T extends true ? Models.BrandColorMap | undefined : undefined;
}

type Setter<T> = T extends true ? ((color: Models.BrandColorMap, editorState?: Draft.EditorState) => void) : undefined;

export function createFontColorSetter<T extends boolean | undefined>(
  isEnabled: T,
  getEditorState: () => Draft.EditorState,
  setEditorState: (state: Draft.EditorState) => void,
  brandStyle: T extends true ? Models.BrandStyleMap | undefined : unknown, // brandStyle is not used for CTA
  colors: T extends true ? Models.BrandColorsList : unknown,
): Setter<T> {
  if (!isEnabled) {
    return undefined as Setter<T>;
  }

  const setter = (brandColor: Models.BrandColorMap, currentEditorState?: Draft.EditorState): void => {
    const { colorHEX, colorName, colorTint } = getBrandColorProperties(
      brandColor,
      brandStyle as Models.BrandStyleMap,
      colors as Models.BrandColorsList,
    );
    const fontColorHex = inlineStyles.getFontColorStyle(colorHEX);
    const fontColorName = inlineStyles.getFontColorNameStyle(colorName);
    const fontColorTint = inlineStyles.getFontColorTintStyle(colorTint);

    const state = currentEditorState || getEditorState();
    let nextEditorState = editorUtils.toggleCustomInlineStyle(state, fontColorHex, Constants.StylePrefix.FONT_COLOR);
    nextEditorState = editorUtils.toggleCustomInlineStyle(nextEditorState, fontColorName, Constants.StylePrefix.FONT_COLOR_NAME);
    nextEditorState = editorUtils.toggleCustomInlineStyle(nextEditorState, fontColorTint, Constants.StylePrefix.FONT_COLOR_TINT);

    setEditorState(nextEditorState);
  };

  return setter as Setter<T>;
}
