import { wrapperOnMouseDownHack } from 'modules/draftjs';
import React from 'react';
import { ToolbarDropdownType } from 'components/EditorToolbar/constants';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { TitleWithNamespace as Title } from 'components/Toolbar/constants';
import { CHANGE_MARK } from 'const';
import StyleDropdown from 'containers/Toolbar/Dropdowns/BrandStyle';
import { BrandStyleMap } from 'models';
import { intlGet } from 'utils/intlGet';
import { IBrandStyleControlProps, IBrandStyleControlState } from './models';
import styles from './styles.module.scss';

class BrandStyleControl extends React.PureComponent<IBrandStyleControlProps, IBrandStyleControlState> {
  private icon = React.createRef<HTMLDivElement>();

  componentDidMount(): void {
    document.addEventListener('click', this.closeDialog);
  }

  componentWillUnmount(): void {
    document?.removeEventListener('click', this.closeDialog);
  }

  private closeDialog = (event: MouseEvent): void => {
    const { activeDropdown, setActiveDropdown } = this.props;
    const isStyleDropdownOpen = activeDropdown === ToolbarDropdownType.BRAND_STYLE;
    const isTargetChevron = this.icon.current.contains(event.target as Node);

    if (!isTargetChevron && isStyleDropdownOpen) {
      setActiveDropdown(null);
    }
  };

  private handleStyleSelection = (style: BrandStyleMap): void => {
    const { setStyle, returnFocusToEditor } = this.props;

    setStyle(style);
    returnFocusToEditor();
  };

  private handleMouseDownHack = (event: React.MouseEvent<HTMLDivElement>): void => {
    wrapperOnMouseDownHack(event);
    const { activeDropdown, setActiveDropdown } = this.props;

    setActiveDropdown(activeDropdown !== ToolbarDropdownType.BRAND_STYLE ? ToolbarDropdownType.BRAND_STYLE : null);
  };

  render() {
    const { activeDropdown, brandStyle, brandStyleChanged, sectionStyles } = this.props;
    const defaultText = intlGet('EditorToolbar.StyleDropdown', 'Default');
    const showDropdown = activeDropdown === ToolbarDropdownType.BRAND_STYLE;
    const changeMark = <span className={styles.changeMark}>{CHANGE_MARK}</span>;

    return (
      <div className={styles.BrandStyleControl} data-title={Title.TEXT_STYLE}>
        <div
          onMouseDown={this.handleMouseDownHack}
          ref={this.icon}
          className={styles.styleControl}
          data-tooltip={brandStyle ? brandStyle.get('name') : intlGet('EditorToolbar.Tooltip', 'FontStyle')}
        >
          <span className={styles.controlText}>
            <span className={styles.brandStyle}>{brandStyle ? brandStyle.get('name') : defaultText}</span>
            {brandStyle && brandStyleChanged && changeMark}
          </span>
          <Icon type={IconType.CHEVRON_DOWN} size="badge" color={showDropdown ? 'primary' : 'secondary'} hoverColor="primary-hover" />
        </div>
        {
          showDropdown &&
          <StyleDropdown
            activeBrandStyleId={brandStyle && brandStyle.get('id')}
            toggleBrandStyle={this.handleStyleSelection}
            sectionStyles={sectionStyles}
          />
        }
      </div>
    );
  }
}

export default BrandStyleControl;
