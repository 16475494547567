import Editor from '@draft-js-plugins/editor';
import _ from 'lodash';
import { undoRedoKeyBindings, applyEditorStateFontStylesForBrandStyles } from 'modules/draftjs';
import { useHandleKeyCommand } from 'modules/draftjs/hooks/useHandleKeyCommand';
import { KeyboardEvent, useCallback, useEffect, useRef } from 'react';
import * as Constants from 'const';
import { DefaultTextBrandStyle } from 'const/Styles';
import { isDefaultBrandStyleAvailable } from 'utils/brandStyles';
import * as editorUtils from 'utils/editor';
import { eventEmitter, EMITTER_EVENTS } from 'utils/eventEmitter';
import { getReferenceNumberOrder } from 'utils/getReferenceNumberOrder';
import useStyles from '../hooks/useStyles';
import { TextProps } from '../models';
import { getBrandStyles, getColors, getFonts } from '../utils/brand';
import { getEditorStateFromProps, setColorForListBullets } from '../utils/editor';
import { getPrioritizedRelation } from '../utils/relation';
import { BrandProps } from './useBrandProps';
import useBrandStyleChange from './useBrandStyleChange';
import useCell from './useCell';
import useEditor from './useEditor';
import useEditorAnchors from './useEditorAnchors';
import useUndo from './useUndo';

export default function useUpdate(
  props: TextProps,
  editorRef: React.RefObject<Editor | null>,
  editorHook: ReturnType<typeof useEditor>,
  brandProps: BrandProps,
  stylesHook: ReturnType<typeof useStyles>,
  cellHook: ReturnType<typeof useCell>,
  brandStyleChangeHook: ReturnType<typeof useBrandStyleChange>,
  editorAnchors: ReturnType<typeof useEditorAnchors>,
  undoHook: ReturnType<typeof useUndo>,
  storeText: () => void,
): void {

  const prevEditorStateRef = useRef(editorHook.editorState);
  const prevPropsRef = useRef(props);

  const handleKeyCommand = useHandleKeyCommand(undoHook.fillUndoStackIfEmpty, undoHook.undo, undoHook.redo);

  const undoRedoGlobalHandler = useCallback((e: KeyboardEvent): void => {
    handleKeyCommand(undoRedoKeyBindings(e));
  }, [handleKeyCommand]);

  useEffect(() => {
    return () => {
      eventEmitter.removeListener(EMITTER_EVENTS.BODY_KEYDOWN, undoRedoGlobalHandler);
    };
  }, [undoRedoGlobalHandler]);

  const shouldReturnFocusToEditorRef = useRef(false);
  useEffect(() => {
    if (shouldReturnFocusToEditorRef.current) {
      editorHook.returnFocusToEditor();
      shouldReturnFocusToEditorRef.current = false;
    }
  }, [editorHook.editorState]);

  useEffect(() => {
    const { current } = prevPropsRef;
    const { editMode, isAutoFitContent } = props;

    const isAutoFitContentChanged = isAutoFitContent && current.isAutoFitContent !== isAutoFitContent;
    const isEditModeChanged = current.editMode !== editMode;

    if (isEditModeChanged || isAutoFitContentChanged) {
      cellHook.resetHeight();
    }
  }, [props.isAutoFitContent, props.editMode]);

  useEffect((): void => {
    const { document, relation, referenceOrder, notEditable, ssi, editMode, activeLayer, projectType } = props;

    const { brandStyles, colors, fonts } = brandProps;
    const referencesOrder = getReferenceNumberOrder(
      getPrioritizedRelation(props),
      referenceOrder,
    );
    const prevReferencesOrder = getReferenceNumberOrder(
      getPrioritizedRelation(prevPropsRef.current),
      prevPropsRef.current.referenceOrder,
    );
    const referenceOrderChanged = referencesOrder !== prevReferencesOrder
      && referencesOrder.some((ref, idx) => ref !== prevReferencesOrder.get(idx));
    const styles = relation.getIn(['styles', activeLayer]);
    const brandStyleChanged = !cellHook.props.isAutoFitContent || (styles && styles.get('brandStyleChanged'));
    const brandStyleId = styles && styles.get('brandStyleId');

    let currentEditorState = editorHook.editorState;

    if (document !== prevPropsRef.current.document || referenceOrderChanged || prevPropsRef.current.editMode !== editMode) {
      currentEditorState = getEditorStateFromProps(props, brandProps);
      currentEditorState = applyEditorStateFontStylesForBrandStyles(currentEditorState, projectType, fonts);
      editorHook.setEditorStateAndOperations(currentEditorState);
    }

    // TODO: check whether we really need setFullSelection or the problem was in other place and was already fixed
    // HACK to trigger Draft Editor re-render when new color styles was provided
    if (
      (!colors.equals(getColors(prevPropsRef.current)) || !fonts.equals(getFonts(prevPropsRef.current)))
      && currentEditorState === editorHook.editorState
    ) {
      currentEditorState = getEditorStateFromProps(props, brandProps);
      currentEditorState = applyEditorStateFontStylesForBrandStyles(currentEditorState, projectType, fonts);
      currentEditorState = editorUtils.setFullSelection(currentEditorState);
      editorHook.setEditorStateAndOperations(currentEditorState);
      shouldReturnFocusToEditorRef.current = true;
    }

    if (
      brandStyles
      && !brandStyles.equals(getBrandStyles(prevPropsRef.current))
      && brandStyleId
      && !brandStyleChanged
    ) {
      const brandStyle = brandStyles.get(brandStyleId);
      currentEditorState = brandStyleChangeHook.toggleBrandStyle(brandStyle, currentEditorState);
      stylesHook.ensureStylesToBeKept(props);
    }

    // we check that the styles is changed without autofit content as in some cases it causes unnecessary triggers of state sync
    const path = ['styles', activeLayer, 'isAutoFitContent'];
    const preparedCurrentStyles = relation.hasIn(path) ? relation.deleteIn(path) : relation;
    const preparedPrevStyles = prevPropsRef.current.relation.hasIn(path)
      ? prevPropsRef.current.relation.deleteIn(path)
      : prevPropsRef.current.relation;

    if (
      relation.get('id') !== prevPropsRef.current.relation.get('id')
      || !preparedCurrentStyles.equals(preparedPrevStyles)
      || activeLayer !== prevPropsRef.current.activeLayer
    ) {
      stylesHook.resetStyles(
        styles,
        brandStyles,
        brandStyleId || DefaultTextBrandStyle,
        brandStyleChanged,
        colors,
      );
    }

    if (currentEditorState !== prevEditorStateRef.current) {
      let newFontFamily = editorUtils.getActiveFontFamily(currentEditorState);

      // don't need to show applied font family if there is no such font in Brand Definition
      // show default font instead in this case
      if (fonts && !fonts.some(font => font.get('name') === newFontFamily.fontFamily)) {
        newFontFamily = {
          fontFamily: Constants.DefaultCustomStyle.FONT_FAMILY as string,
          characterStyle: undefined,
        };
      }

      if (!_.isEqual(editorHook.activeFontFamily, newFontFamily)) {
        editorHook.setActiveFontFamily(newFontFamily);
      }

      editorAnchors.setAnchors(currentEditorState);
    }

    if (currentEditorState.getCurrentContent() !== prevEditorStateRef.current.getCurrentContent()) {
      setColorForListBullets(
        editorRef.current,
        currentEditorState,
        stylesHook.styles.brandStyle,
        colors,
      );
    }

    if (notEditable && (prevPropsRef.current.ssi.get('position') !== ssi.get('position'))) {
      // set new anchors only after ssi element has been repainted
      setTimeout(() => editorAnchors.setAnchors(currentEditorState));
    }

    if (prevPropsRef.current.editMode !== editMode) {
      if (editMode) {
        editorHook.returnFocusToEditor();
        // useDefaultStyles
        const noComponentAssigned = !relation.getIn(['documentId', activeLayer]) || !document;
        // NOTE: brand style and font color must be ignored if there is no document assigned
        if (!brandStyleId && noComponentAssigned) {
          if (isDefaultBrandStyleAvailable(brandStyles, props.sectionStyles)) {
            brandStyleChangeHook.toggleBrandStyle(
              brandStyles.get(Constants.Styles.DefaultTextBrandStyle),
              currentEditorState,
              false,
            );
          } else {
            editorHook.setters.fontColor(
              editorUtils.getBrandColor(colors, Constants.DefaultCustomStyle.FONT_COLOR),
              currentEditorState,
            );
          }
          stylesHook.ensureStylesToBeKept(props);
        }
        editorHook.setOperations([]);
        cellHook.resetCellWidhtAndHeightChange();
        props.saveAppState();
        eventEmitter.addListener(EMITTER_EVENTS.BODY_KEYDOWN, undoRedoGlobalHandler);
      } else {
        storeText();
        if (undoHook.isUndoDisabled) {
          props.cancel();
        }
        undoHook.reset();
        eventEmitter.removeListener(EMITTER_EVENTS.BODY_KEYDOWN, undoRedoGlobalHandler);
      }
    }

    prevEditorStateRef.current = editorHook.editorState;
    prevPropsRef.current = props;
  });
}
