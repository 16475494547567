import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import AssetActions from 'components/AssetActions';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import LoadingOverlay from 'components/LoadingOverlay';
import { ProcessType } from 'const';
import { getImageResolution } from 'utils/getImageResolution';
import { ImageBoxProps } from './models';
import styles from './styles.module.scss';

const ImageBox: React.FunctionComponent<ImageBoxProps> = (props) => {
  const {
    container,
    showImageDetails,
    deleteImage,
    image,
    imageCount,
    isActive,
    isDragging,
    isImageBeingUploaded,
    isSelected,
    onImageClick,
  } = props;

  const id = image.get('id');
  const name = image.get('name');
  const number = image.get('number');
  const documentId = image.get('documentId');
  const internalImageInfo = image.get('_internalInfo');
  const imageSource = internalImageInfo && internalImageInfo.get('source');
  const resolution = getImageResolution(internalImageInfo);
  const product = image.get('product');
  const productName = product && product.first();

  const onClick = (): void => {
    onImageClick && onImageClick(id, documentId);
  };

  return (
    <div className={styles.ImageBox} ref={container}>
      <div
        className={classNames(styles.box, {
          [styles.box__active]: isActive,
          [styles.box__draggable]: isDragging,
          [styles.box__selected]: isSelected,
        })}
        onClick={onClick}
      >
        <LoadingOverlay isLocked={isImageBeingUploaded} activeProcessType={ProcessType.DOCUMENT_UPLOADING} spinnerSize="md" />
        {!isDragging &&
          <>
            {imageSource
              ? <img className={styles.image} src={imageSource} />
              : <div className={styles.icon}>
                <Icon type={IconType.IMAGE} size="md" color="disabled" />
              </div>
            }
            {
              !isImageBeingUploaded &&
              <AssetActions
                assetClickable={!!onImageClick}
                onPreviewOpen={showImageDetails}
                onDelete={deleteImage}
              />
            }
            {_.isFinite(imageCount) && !isImageBeingUploaded &&
              <div className={styles.counter}>{imageCount}</div>
            }
          </>
        }
      </div>
      <div className={styles.info}>
        <div className={styles.primary}>{name}</div>
        <div className={styles.secondary}>{resolution}</div>
        <div className={styles.number}>{number}</div>
        {productName && <div className={styles.product}>{productName}</div>}
      </div>
    </div>
  );
};

export default ImageBox;
