import Draft from 'draft-js';
import { useMemo } from 'react';
import * as Models from 'models';
import * as editorUtils from 'utils/editor';

type Options = {
  colors: Models.BrandColorsList;
  fonts: Models.BrandFontsList;
};

export function useCustomStyleFn(
  options: Options,
): ((styles: Draft.DraftInlineStyle) => Models.CSSInlineStyle) {
  const { colors, fonts } = options;

  return useMemo(
    () => editorUtils.getCustomInlineStyle(colors, fonts),
    [colors, fonts],
  );
}
