import classNames from 'classnames';
import React, { useCallback } from 'react';

import AddImageItem from 'components/AddImageItem';
import ImageBox from 'components/ImageBox';
import { ImageMap } from 'models';
import { MobileViewImageProps } from './models';
import styles from './styles.module.scss';

const MobileViewImage: React.FunctionComponent<MobileViewImageProps> = (props) => {
  const {
    imagesForAssetsPanel,
    showModal,
    mobileViewImage,
    toggleMobileViewImage,
    documentIdsAreBeingUploaded,
    className,
  } = props;

  const setImage = useCallback(
    (imageId: string, number: number): void => {
      toggleMobileViewImage(
        mobileViewImage.set('id', imageId).set('documentId', number),
      );
    },
    [mobileViewImage, toggleMobileViewImage],
  );

  function isActive(image: ImageMap): boolean {
    return image.get('id') === mobileViewImage.get('id')
    || image.get('documentId') === mobileViewImage.get('documentId');
  }

  return (
    <div className={classNames(styles.MobileViewImage, className)}>
      <ul className={styles.list}>
        <li className={styles.item}>
          <AddImageItem showModal={showModal} />
        </li>

        {imagesForAssetsPanel.valueSeq().map((image) => {
          const imageId = image.get('id');

          return (
            <li className={styles.item} key={imageId}>
              <ImageBox
                image={image}
                isActive={isActive(image)}
                isImageBeingUploaded={documentIdsAreBeingUploaded.has(imageId)}
                onImageClick={setImage}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MobileViewImage;
