import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { rootDocument as rootDocumentSelector } from 'containers/RootDocument/selectors';
import { TextAbbreviationDocument } from 'models';
import { createAbbreviation, getAbbreviations } from '../../../api';
import useFilteredAbbreviations from '../../../hooks/useFilteredAbbreviations';
import { setTextAbbreviations } from '../../../store/actions';
import styles from '../styles.module.scss';
import NewAbbreviation from './NewAbbreviation';

type Props = {
  term: string;
  abbreviationDocument?: TextAbbreviationDocument;
  onSelect: (abbreviationDocumentId: TextAbbreviationDocument['id']) => void;
  className?: string;
  onDefinitionCancel?: ()=>void;
};

const AbbreviationsList: React.FunctionComponent<Props> = (props: Props) => {
  const { className: abbreviationListClassName, term, abbreviationDocument, onSelect, onDefinitionCancel } = props;
  const rootDocument = useSelector(rootDocumentSelector);
  const dispatch = useDispatch();

  const abbreviationsList = useFilteredAbbreviations(term);
  const addAbbreviation = async (_term: string, _definition: string): Promise<TextAbbreviationDocument['id']> => {
    const rootDocumentId = rootDocument.get('documentId');

    const createdAbbreviationId = await createAbbreviation({
      rootDocumentId,
      abbreviation: {
        term: _term,
        definition: _definition,
      },
    });

    const textAbbreviationDocuments = await getAbbreviations(rootDocumentId);

    dispatch(setTextAbbreviations(textAbbreviationDocuments));

    return createdAbbreviationId;
  };
  const onAddAbbreviation = useCallback(
    (newDefinition: string): Promise<void> => addAbbreviation(term, newDefinition).then(onSelect),
    [term, addAbbreviation, onSelect],
  );

  const existingDefinitions = abbreviationsList.map(({ abbreviation }) => abbreviation.definition.toLowerCase());

  if (!abbreviationsList) {
    return (
      <div className={abbreviationListClassName}>
        <NewAbbreviation onAdd={onAddAbbreviation} existingDefinitions={existingDefinitions} onCancel={onDefinitionCancel}/>
      </div>
    );
  }

  return (
    <div className={abbreviationListClassName}>
      <div className={classNames(styles.abbreviationsList)}>
        {abbreviationsList.map(_abbreviationDocument => (
          <button
            key={_abbreviationDocument.id}
            className={classNames({ selected: _abbreviationDocument.id === abbreviationDocument?.id })}
            onClick={(): void => onSelect(_abbreviationDocument.id)}
          >
            {_abbreviationDocument.abbreviation.definition}
          </button>
        ))}
      </div>
      <NewAbbreviation onAdd={onAddAbbreviation} existingDefinitions={existingDefinitions} onCancel={onDefinitionCancel}/>
    </div>
  );
};

AbbreviationsList.defaultProps = {
  className: undefined,
};

export default AbbreviationsList;
