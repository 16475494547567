import { Schemas } from 'services/ArtboardConverter/models';
import { createFont } from './createFont';
import { createScriptFormatting } from './createScriptFormatting';
import { createTextFormatting } from './createTextFormatting';

export const createPart = ({
  bulletColor = '',
  text = '',
  lineHeight,
  lineHeightPx,
  ...args
} = {} as Partial<Schemas.Part>): Schemas.Part => ({
  ...createScriptFormatting(args),
  ...createTextFormatting(args),
  ...createFont(args),
  text,
  lineHeight,
  lineHeightPx,
  bulletColor,
});
