import Immutable from 'immutable';

import { Layer } from 'const';
import { createColumnRelation, createLayeredRelation, createRowRelation } from 'factories/relationFactory';
import * as Models from 'models';
import { addColumn } from 'utils/addColumn';
import { toImmutable } from 'utils/immutable';
import { getLayoutWidth } from 'utils/layouts/getLayoutWidth';
import { isRowRelation } from 'utils/relations/isRowRelation';

export function addColumnToLayout(layout: Models.LayoutMap, relations: Models.LayeredRelationsMap, sectionWidth: number, layer: Layer) {
  const layoutRelation = relations.get(layout.get('relationId')) as Models.ColumnRelationMap;
  const columnsWidth = getLayoutWidth(layout, sectionWidth, { withoutExtraWidth: true });
  const newLayeredRelation = createLayeredRelation(null, { layer });

  let resultRelations = relations.set(newLayeredRelation.id, toImmutable(newLayeredRelation));
  let resultLayout = layout;

  if (layoutRelation.get('relationIds').size === 1) {
    const layoutRelationChildId = layoutRelation.get('relationIds').first<string>();
    const layoutRelationChild = resultRelations.get(layoutRelationChildId) as Models.RowRelationMap | Models.RegularRelationMap;

    if (isRowRelation(layoutRelationChild)) {
      resultRelations = resultRelations
        .update(layoutRelationChildId, (layoutRelationChild: Models.RowRelationMap) => layoutRelationChild
          .update('relationIds', relationIds => relationIds.push(newLayeredRelation.id))
          .updateIn(['styles', 'columnsWidth'], oldColumnsWidth => addColumn(oldColumnsWidth)),
        );
    } else {
      const newRowRelation = createRowRelation({ relationIds: [layoutRelationChildId, newLayeredRelation.id] }, { width: columnsWidth });
      resultRelations = resultRelations
        .set(newRowRelation.id, toImmutable(newRowRelation))
        .update(resultLayout.get('relationId'), (layoutRelation: Models.ColumnRelationMap) => layoutRelation
          .update('relationIds', () => Immutable.List([newRowRelation.id])),
        );
    }
  } else {
    const newRowRelation = createRowRelation({ relationIds: [layoutRelation.get('id'), newLayeredRelation.id] }, { width: columnsWidth });
    const newColumnRelation = createColumnRelation({ relationIds: [newRowRelation.id] });

    resultRelations = resultRelations
      .set(newRowRelation.id, toImmutable(newRowRelation))
      .set(newColumnRelation.id, toImmutable(newColumnRelation));

    resultLayout = resultLayout.set('relationId', newColumnRelation.id);
  }

  return {
    relations: resultRelations,
    layout: resultLayout,
  };
}
