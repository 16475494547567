import { CellAction, useCellActionsExecutor } from 'context/CellActions';
import React, { useCallback } from 'react';
import { Notifications } from 'services/Notifications';
import { intlGet } from 'utils/intlGet';
import AbbreviationControl from '../AbbreviationControl';
import { useAbbreviationControl } from '../AbbreviationControl/useAbbreviationControl';
import RelationAbbreviationPanel from './components/RelationAbbreviationPanel';
import styles from './styles.module.scss';

interface IRelationAbbreviationControlProps {
  selectedAbbreviationIDs: string[];
  onChange: (selectedAbbreviationIDs: string[]) => void;
  className?: string;
}

const RelationAbbreviationControl: React.FunctionComponent<IRelationAbbreviationControlProps> = (props) => {
  const {
    selectedAbbreviationIDs,
    onChange,
    className,
  } = props;

  const { isOpen, toggle, toggleOn, toggleOff } = useAbbreviationControl();

  const onRemoveAbbreviation = useCallback((abbreviationDocumentId: string): void => {
    const updatedIds = selectedAbbreviationIDs.filter(id => abbreviationDocumentId !== id);
    onChange(updatedIds);
    Notifications.success(intlGet('Notification.Success', 'AbbreviationRemoved'));
    if (!updatedIds.length) {
      toggleOff();
    }
  }, [onChange, selectedAbbreviationIDs]);

  const onAddAbbreviation = useCallback((abbreviationId: string): void => {
    const newSelectedIds = [...selectedAbbreviationIDs, abbreviationId];
    onChange(newSelectedIds);
  }, [onChange, selectedAbbreviationIDs]);

  const onReplaceAbbreviation = useCallback((
    prevAbbreviationId: string,
    newAbbreviationId: string,
  ): void => {

    const newSelectedIds = [...selectedAbbreviationIDs];
    const editedIndex = newSelectedIds.indexOf(prevAbbreviationId);
    if (editedIndex >= 0) {
      newSelectedIds.splice(editedIndex, 1, newAbbreviationId);
    } else {
      newSelectedIds.push(newAbbreviationId);
    }

    onChange(newSelectedIds);
  }, [onChange, selectedAbbreviationIDs]);


  useCellActionsExecutor(CellAction.OPEN_ABBREVIATIONS_PANEL, toggleOn);

  useCellActionsExecutor(CellAction.REMOVE_ABBREVIATION, (id) => {
    if (!selectedAbbreviationIDs.includes(id)) {
      return false;
    }
    onRemoveAbbreviation(id);

    return true;
  });

  return (
    <div className={styles.flexContainer}>
      <AbbreviationControl
        isOpen={isOpen}
        toggle={toggle}
        toggleOff={toggleOff}
        isDisabled={false}
        className={className}
      >
        <RelationAbbreviationPanel
          selectedAbbreviationIDs={selectedAbbreviationIDs}
          onAdd={onAddAbbreviation}
          onReplace={onReplaceAbbreviation}
          onRemove={onRemoveAbbreviation}
        />
      </AbbreviationControl>
    </div>
  );
};

export default RelationAbbreviationControl;
